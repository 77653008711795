<template>
    <div class="card p-5" style="font-family: 'Inter', sans-serif">
        <HeaderPanel :loading="loading" nomeTelaDoManual="relatorios-e-indicadores-solicitacao-por-cliente" tittle="Solicitação por cliente" />
        <div class="grid">
            <div class="col-12 lg:col-2">
                <label for="txtFilterCliente">Pesquisar por</label>
                <Dropdown
                    v-model="tipoFiltro"
                    :disabled="loading"
                    :options="tipoFiltroOptions"
                    class="w-full mt-1"
                    dataKey="id"
                    optionLabel="label"
                    optionValue="value"
                    placeholder="Selecione..."
                    @change="limparIds"
                />
            </div>
            <div v-if="tipoFiltro == 1" class="col-12 lg:col-4">
                <label for="txtFilterCliente">Cliente</label>
                <MultiSelectCliente
                    v-model="customerIds"
                    :disabled="loading"
                    class="w-full mt-1"
                    dataKey="id"
                    optionLabel="name"
                    optionValue="id"
                    placeholder="Selecione os clientes..."
                />
            </div>
            <div v-if="tipoFiltro == 2" class="col-12 lg:col-4">
                <label for="txtFilterCliente" class="mb-1">Grupo</label>
                <MultiSelectGrupos v-model="grupoIds" dataKey="id" class="w-full" optionValue="id" placeholder="Selecione os grupos..." />
            </div>
            <div v-if="tipoFiltro == 3" class="col-12 lg:col-4">
                <label for="txtFilterCliente" class="mb-1">Unidade</label>
                <MultiSelectUnidadePorUsuario
                    v-model="customerBranchIds"
                    class="w-full"
                    dataKey="id"
                    optionValue="id"
                    placeholder="Selecione as unidades..."
                />
            </div>
            <div v-if="tipoFiltro == 4" class="col-12 lg:col-4">
                <label for="txtFilterCliente" class="mb-1">Solicitantes</label>
                <MultiSelectUsuariosPorAgendamentos
                    v-model="usersIds"
                    class="w-full"
                    dataKey="id"
                    optionValue="id"
                    placeholder="Selecione os solicitantes..."
                />
            </div>
            <div class="col-12 lg:col-6">
                <div class="flex justify-content-end flex-row h-full">
                    <div class="flex align-items-end">
                        <Button
                            :loading="loading"
                            class="h-3rem"
                            icon="pi pi-sync"
                            label="Recarregar"
                            loadingIcon="pi pi-sync pi-spin"
                            type="button"
                            @click="loadAllComponentes"
                        />
                    </div>
                </div>
            </div>
        </div>
        <div class="layout-content">
            <div class="grid h-auto">
                <div class="col-12 sm:col-12 lg:col-12 md:col-12 p-0 sm:pt-2 lg:py-3 md:py-3 lg:px-0 lg:py-3 md:px-0 md:py-3 py-3">
                    <div class="p-5 border-round-md border-1 border-300 border">
                        <QuantidadeConfirmacaoComparecimentoChart
                            ref="refQuantidadeConfirmacaoComparecimentoChart"
                            :customerBranchIds="customerBranchIds"
                            :customerIds="customerIds"
                            :grupoIds="grupoIds"
                            :usersIds="usersIds"
                        />
                    </div>
                </div>
                <div class="col-12 sm:col-12 lg:col-12 md:col-12 p-0 sm:pt-2 lg:py-3 md:py-3 lg:px-0 lg:py-3 md:px-0 md:py-3 py-3">
                    <div class="p-5 border-round-md border-1 border-300 border">
                        <QuantidadeAgendamentosPorStatusChart
                            ref="refQuantidadeAgendamentosPorStatusChart"
                            :customerBranchIds="customerBranchIds"
                            :customerIds="customerIds"
                            :grupoIds="grupoIds"
                            :usersIds="usersIds"
                        />
                    </div>
                </div>
                <div class="col-12 sm:col-12 lg:col-8 md:col-12 p-0 sm:pt-2 lg:py-3 md:py-3 lg:pl-0 md:pl-0 lg:pr-3 py-3">
                    <div class="p-5 border-round-md border-1 border-300 border" style="height: 30rem">
                        <QuantidadeAgendamentosConcluidosChart
                            ref="refQuantidadeAgendamentosConcluidosChart"
                            :customerIds="customerIds"
                            :grupoIds="grupoIds"
                            :customerBranchIds="customerBranchIds"
                            :usersIds="usersIds"
                        />
                    </div>
                </div>
                <div class="col-12 sm:col-12 lg:col-2 md:col-6 p-0 sm:pt-2 lg:py-3 md:py-3 lg:px-3 md:pr-3 py-3">
                    <div class="p-5 border-round-md border-1 border-300 border" style="height: 30rem">
                        <QuantidadeDeLaudosPCDeEnquadramentosPanel
                            ref="refQuantidadeDeLaudosPCDeEnquadramentosPanel"
                            :customerIds="customerIds"
                            :grupoIds="grupoIds"
                            :customerBranchIds="customerBranchIds"
                            :usersIds="usersIds"
                        />
                    </div>
                </div>
                <div class="col-12 sm:col-12 lg:col-2 md:col-6 p-0 sm:pt-2 lg:py-3 md:py-3 lg:pl-3 md:pl-3 lg:pr-0 md:pr-0 py-3">
                    <div class="p-5 border-round-md border-1 border-300 border" style="height: 30rem">
                        <QuantidadeDeClientesComAsoRetidoPanel
                            ref="refQuantidadeDeClientesComAsoRetidoPanel"
                            :customerIds="customerIds"
                            :grupoIds="grupoIds"
                            :usersIds="usersIds"
                        />
                    </div>
                </div>
                <div class="col-12 sm:col-12 lg:col-8 md:col-12 p-0 sm:pt-2 lg:py-3 md:py-3 lg:pl-0 md:pl-0 lg:pr-3 py-3">
                    <div class="p-5 border-round-md border-1 border-300 border" style="height: 35rem">
                        <QuantidadeAgendamentosMetodosRealizadosChart
                            ref="refQuantidadeAgendamentosMetodosRealizadosChart"
                            :customerIds="customerIds"
                            :grupoIds="grupoIds"
                            :customerBranchIds="customerBranchIds"
                            :usersIds="usersIds"
                        />
                    </div>
                </div>
                <div class="col-12 sm:col-12 lg:col-8 md:col-12 p-0 sm:pt-2 lg:py-3 md:py-3 lg:pl-0 md:pl-0 lg:pr-3 py-3">
                    <div class="p-5 border-round-md border-1 border-300 border" style="height: 35rem">
                        <QuantidadeSolicitacoesRealizadasChart
                            ref="refQuantidadeSolicitacoesRealizadasChart"
                            :customerIds="customerIds"
                            :grupoIds="grupoIds"
                            :customerBranchIds="customerBranchIds"
                            :usersIds="usersIds"
                        />
                    </div>
                </div>
                <div class="col-12 sm:col-12 lg:col-4 md:col-12 p-0 sm:pt-2 lg:py-3 md:py-3 lg:pl-3 lg:pr-0 md:pr-0 py-3">
                    <div
                        class="p-5 border-round-md border-1 border-300 border lg:overflow-y-hidden lg:overflow-x-hidden md:overflow-y-hidden md:overflow-x-auto sm:overflow-y-hidden sm:overflow-x-auto overflow-y-hidden overflow-x-auto"
                        style="height: 35rem"
                    >
                        <QuantidadePorTipoExameChart
                            ref="refQuantidadePorTipoExameChart"
                            :customerIds="customerIds"
                            :grupoIds="grupoIds"
                            :customerBranchIds="customerBranchIds"
                            :usersIds="usersIds"
                        />
                    </div>
                </div>
                <div class="col-12 sm:col-12 lg:col-12 md:col-12 p-0 sm:pt-2 lg:py-3 md:py-3 lg:px-0 lg:py-3 md:px-0 md:py-3 py-3">
                    <div class="p-5 border-round-md border-1 border-300 border" style="height: 35rem">
                        <QuantidadeReagendadosChart
                            ref="refQuantidadeReagendadosChart"
                            :customerIds="customerIds"
                            :grupoIds="grupoIds"
                            :customerBranchIds="customerBranchIds"
                            :usersIds="usersIds"
                        />
                    </div>
                </div>
                <div class="col-12 sm:col-12 lg:col-12 md:col-12 p-0 sm:pt-2 lg:py-3 md:py-3 lg:px-0 lg:py-3 md:px-0 md:py-3 py-3">
                    <div class="p-5 border-round-md border-1 border-300 border" style="height: 35rem">
                        <QuantidadeMotivoReagendamentosChart
                            ref="refQuantidadeMotivoReagendamentosChart"
                            :customerIds="customerIds"
                            :grupoIds="grupoIds"
                            :customerBranchIds="customerBranchIds"
                            :usersIds="usersIds"
                        />
                    </div>
                </div>
                <div class="col-12 sm:col-12 lg:col-12 md:col-12 p-0 sm:pt-2 lg:py-3 md:py-3 lg:px-0 lg:py-3 md:px-0 md:py-3 py-3">
                    <div class="p-5 border-round-md border-1 border-300 border" style="height: 35rem">
                        <QuantidadeAgendamentosAutoagendamentoChart
                            ref="refQuantidadeAgendamentosAutoagendamentoChart"
                            :customerIds="customerIds"
                            :grupoIds="grupoIds"
                            :customerBranchIds="customerBranchIds"
                            :usersIds="usersIds"
                        />
                    </div>
                </div>
                <div class="col-12 sm:col-12 lg:col-12 md:col-12 p-0 sm:pt-2 lg:py-3 md:py-3 lg:px-0 lg:py-3 md:px-0 md:py-3 py-3">
                    <div class="p-5 border-round-md border-1 border-300 border" style="height: 35rem">
                        <QuantidadeAtendimentosForaDoPeriodoChart
                            ref="refQuantidadeAtendimentosForaDoPeriodoChart"
                            :customerIds="customerIds"
                            :grupoIds="grupoIds"
                            :customerBranchIds="customerBranchIds"
                            :usersIds="usersIds"
                        />
                    </div>
                </div>
                <div class="col-12 sm:col-12 lg:col-12 md:col-12 p-0 sm:pt-2 lg:py-3 md:py-3 lg:px-0 lg:py-3 md:px-0 md:py-3 py-3">
                    <div class="p-5 border-round-md border-1 border-300 border" style="height: 35rem">
                        <AgendamentosPorPrestadorGrid
                            ref="refAgendamentosPorPrestadorGrid"
                            :customerIds="customerIds"
                            :grupoIds="grupoIds"
                            :customerBranchIds="customerBranchIds"
                            :usersIds="usersIds"
                        />
                    </div>
                </div>
                <div class="col-12 sm:col-12 lg:col-4 md:col-12 p-0 sm:pt-2 lg:py-3 md:py-3 lg:pr-3 lg:pr-0 md:pr-0 md:pl-0 py-3">
                    <div
                        class="p-5 border-round-md border-1 border-300 border lg:overflow-y-hidden lg:overflow-x-hidden md:overflow-y-hidden md:overflow-x-auto sm:overflow-y-hidden sm:overflow-x-auto overflow-y-hidden overflow-x-auto"
                        style="height: 35rem"
                    >
                        <QuantidadeAgendamentoPorEstadoChart
                            ref="refQuantidadeAgendamentoPorEstadoChart"
                            :customerIds="customerIds"
                            :grupoIds="grupoIds"
                            :customerBranchIds="customerBranchIds"
                            :usersIds="usersIds"
                        />
                    </div>
                </div>
                <div class="col-12 sm:col-12 lg:col-8 md:col-12 p-0 sm:pt-2 lg:py-3 md:py-3 lg:pr-0 md:pr-0 lg:pl-3 md:pl-0 py-3">
                    <div class="p-5 border-round-md border-1 border-300 border" style="height: 35rem">
                        <TopDezDeAgendamentosPorCidadesGrid
                            ref="refTopDezDeAgendamentosPorCidadesGrid"
                            :customerIds="customerIds"
                            :grupoIds="grupoIds"
                            :customerBranchIds="customerBranchIds"
                            :usersIds="usersIds"
                        />
                    </div>
                </div>
                <div class="col-12 sm:col-12 lg:col-12 md:col-12 p-0 sm:pt-2 lg:py-3 md:py-3 lg:px-0 lg:py-3 md:px-0 md:py-3 py-3">
                    <div
                        class="p-5 border-round-md border-1 border-300 border lg:overflow-y-hidden lg:overflow-x-hidden md:overflow-y-hidden md:overflow-x-auto sm:overflow-y-hidden sm:overflow-x-auto overflow-y-hidden overflow-x-auto"
                        style="height: 35rem"
                    >
                        <CidadesSemPrestadoresGrid
                            ref="refCidadesSemPrestadoresGrid"
                            :customerIds="customerIds"
                            :grupoIds="grupoIds"
                            :customerBranchIds="customerBranchIds"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import HeaderPanel from '../components/HeaderPanel.vue';
import QuantidadeSolicitacoesRealizadasChart from './components/QuantidadeSolicitacoesRealizadasChart.vue';
import QuantidadePorTipoExameChart from './components/QuantidadePorTipoExameChart.vue';
import QuantidadeAgendamentosConcluidosChart from './components/QuantidadeAgendamentosConcluidosChart.vue';
import QuantidadeAtendimentosForaDoPeriodoChart from './components/QuantidadeAtendimentosForaDoPeriodoChart.vue';
import QuantidadeDeLaudosPCDeEnquadramentosPanel from './components/QuantidadeDeLaudosPCDeEnquadramentosPanel.vue';
import QuantidadeDeClientesComAsoRetidoPanel from './components/QuantidadeDeClientesComAsoRetidoPanel.vue';
import QuantidadeReagendadosChart from './components/QuantidadeReagendadosChart.vue';
import QuantidadeMotivoReagendamentosChart from './components/QuantidadeMotivoReagendamentosChart.vue';
import AgendamentosPorPrestadorGrid from './components/AgendamentosPorPrestadorGrid';
import QuantidadeAgendamentoPorEstadoChart from './components/QuantidadeAgendamentoPorEstadoChart.vue';
import TopDezDeAgendamentosPorCidadesGrid from './components/TopDezDeAgendamentosPorCidadesGrid.vue';
import QuantidadeAgendamentosPorStatusChart from './components/QuantidadeAgendamentosPorStatusChart.vue';
import MultiSelectCliente from '../../clientes/components/MultiSelectCliente.vue';
import MultiSelectUnidadePorUsuario from '../../unidades/components/MultiSelectUnidadePorUsuario.vue';
import MultiSelectUsuariosPorAgendamentos from '../../usuarios/components/MultiSelectUsuariosPorAgendamentos.vue';
import CidadesSemPrestadoresGrid from './components/CidadesSemPrestadoresGrid.vue';
import { computed, nextTick, ref, watch } from 'vue';
import { getCurrentCustomers } from '@/services/store';
import { useToast } from 'primevue/usetoast';
import MultiSelectGrupos from '../../grupos/components/MultiSelectGrupos.vue';
import QuantidadeAgendamentosMetodosRealizadosChart from './components/QuantidadeAgendamentosMetodosRealizadosChart.vue';
import QuantidadeConfirmacaoComparecimentoChart from './components/QuantidadeConfirmacaoComparecimentoChart.vue';
import QuantidadeAgendamentosAutoagendamentoChart from './components/QuantidadeAgendamentosAutoagendamentoChart.vue';

const grupoIds = ref([]);
const customerIds = ref([]);
const customerBranchIds = ref([]);
const usersIds = ref([]);
const toast = useToast();
const tipoFiltro = ref(1);
const tipoFiltroOptions = ref([
    { label: 'Clientes', value: 1 },
    { label: 'Grupos', value: 2 },
    { label: 'Unidades', value: 3 },
    { label: 'Solicitantes', value: 4 }
]);
const loading = ref(false);

const refQuantidadeDeLaudosPCDeEnquadramentosPanel = ref(null);
const refQuantidadeDeClientesComAsoRetidoPanel = ref(null);
const refQuantidadeSolicitacoesRealizadasChart = ref(null);
const refQuantidadePorTipoExameChart = ref(null);
const refQuantidadeReagendadosChart = ref(null);
const refQuantidadeAgendamentosConcluidosChart = ref(null);
const refQuantidadeAtendimentosForaDoPeriodoChart = ref(null);
const refQuantidadeAgendamentoPorEstadoChart = ref(null);
const refTopDezDeAgendamentosPorCidadesGrid = ref(null);
const refCidadesSemPrestadoresGrid = ref(null);
const refAgendamentosPorPrestadorGrid = ref(null);
const refQuantidadeAgendamentosPorStatusChart = ref(null);
const refQuantidadeMotivoReagendamentosChart = ref(null);
const refQuantidadeAgendamentosMetodosRealizadosChart = ref(null);
const refQuantidadeConfirmacaoComparecimentoChart = ref(null);
const refQuantidadeAgendamentosAutoagendamentoChart = ref(null);

const currentCustomer = computed(() => getCurrentCustomers());

watch(currentCustomer, (novoValor) => {
    if (JSON.stringify(novoValor) !== JSON.stringify(customerIds.value)) {
        customerIds.value = currentCustomer.value;
        loadAllComponentes();
    }
});
watch(customerIds, () => nextTick(() => loadAllComponentes()));
watch(grupoIds, () => nextTick(() => loadAllComponentes()));
watch(customerBranchIds, () => nextTick(() => loadAllComponentes()));
watch(usersIds, () => nextTick(() => loadAllComponentes()));

function limparIds() {
    if (grupoIds.value.length) grupoIds.value = [];
    if (customerIds.value.length) customerIds.value = [];
    if (customerBranchIds.value.length) customerBranchIds.value = [];
    if (usersIds.value.length) usersIds.value = [];
}
async function loadAllComponentes() {
    loading.value = true;
    try {
        await Promise.all([
            refQuantidadeDeLaudosPCDeEnquadramentosPanel.value.queryData(),
            refQuantidadeDeClientesComAsoRetidoPanel.value.queryData(),
            refQuantidadeSolicitacoesRealizadasChart.value.queryData(),
            refQuantidadePorTipoExameChart.value.queryData(),
            refQuantidadeReagendadosChart.value.queryData(),
            refQuantidadeAgendamentosConcluidosChart.value.queryData(),
            refQuantidadeAtendimentosForaDoPeriodoChart.value.queryData(),
            refQuantidadeAgendamentoPorEstadoChart.value.queryData(),
            refTopDezDeAgendamentosPorCidadesGrid.value.queryData(),
            refCidadesSemPrestadoresGrid.value.queryData(),
            refAgendamentosPorPrestadorGrid.value.queryData(),
            refQuantidadeAgendamentosPorStatusChart.value.queryData(),
            refQuantidadeMotivoReagendamentosChart.value.queryData()
        ]);
    } catch (error) {
        toast.add({
            severity: 'info',
            summary: 'Ops!',
            detail: 'Tivemos algum problema ao carregar os gráficos, por favor clique em recarregar!',
            life: 3000
        });
    }
    loading.value = false;
}
</script>
